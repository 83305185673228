<template>
    <div id="production-init">
        <h1 class="title">{{$t('production.select.select-day')}}</h1>

        <div class="channels">
            <div class="item">
                <div class="name">{{$t('production.select.restaurant')}}</div>
                <div class="selector">
                    <select v-model="currentGeneral" class="input" @change="changeType($event, 'general')">
                        <option value="weekday">{{$t('production.select.last')}} {{ weekday }}</option>
                        <option value="custom">{{$t('production.select.select-date')}}</option>
                    </select>
                    <input v-if="currentGeneral == 'custom'" v-model="dateGeneral" class="input date" type="date" @change="changeDate($event, 'general')" :max="maxDate" :min="minDate" />
                </div>
                <template v-if="sales">
                    <div class="chart">
                        <ChartSales :data="sales.general.sales_forecast"></ChartSales>
                    </div>
                    <div class="summary">
                        {{$t('production.select.sale')}} <span>{{ sales.general.total }}€</span>
                    </div>
                </template>
            </div>
            <div class="item">
                <div class="name">{{$t('production.select.delivery')}}</div>
                <div class="selector">
                    <select v-model="currentDelivery" class="input" @change="changeType($event, 'delivery')">
                        <option value="weekday">{{$t('production.select.last')}} {{ weekday }}</option>
                        <option value="custom">{{$t('production.select.select-date')}}</option>
                    </select>
                    <input v-if="currentDelivery == 'custom'" v-model="dateDelivery" class="input date" type="date" @change="changeDate($event, 'delivery')" :max="maxDate" :min="minDate" />
                </div>

                <template v-if="sales">
                    <div class="chart">
                        <ChartSales :data="sales.delivery.sales_forecast"></ChartSales>
                    </div>
                    <div class="summary">
                        {{$t('production.select.sale')}} <span>{{ sales.delivery.total }}€</span>
                    </div>
                </template>
            </div>
        </div>

        <div class="btnExit">
            <button class="btn save-changes" :class="{ disabled: !((currentGeneral == 'weekday' || dateGeneral != dateDefault) && (currentDelivery == 'weekday' || dateDelivery != dateDefault)) }" @click="confirmForecast()">{{$t('production.select.confirm')}}</button>
        </div>
    </div>
</template>

<script>
import ChartSales from './chartSales.vue'

export default {
    components: {
        ChartSales
    },
    name: 'ProductionInit',
    props: { date: { type: String } },
    data() {
        return {
            dateDefault: moment(this.date).locale('en').format('dddd').toLowerCase(),
            dateGeneral: moment(this.date).locale('en').format('dddd').toLowerCase(),
            dateDelivery: moment(this.date).locale('en').format('dddd').toLowerCase(),
            weekday: moment(this.date).locale('en').format('dddd').toLowerCase(),
            maxDate: moment().format('YYYY-MM-DD'),
            minDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
            currentGeneral: 'weekday',
            currentDelivery: 'weekday'
        }
    },
    computed: {
        sales() {
            return this.$store.getters['production/getSales']
        }
    },
    created() {
        this.load()
    },
    methods: {
        load() {
            let self = this
            this.$overlay.loading()
            var params = {
                date1: this.dateGeneral,
                date2: this.dateDelivery
            }
            this.$store.dispatch('production/loadSales', params).then(() => {
                self.$overlay.hide()
            })
        },
        changeType(e, channel) {
            if (e.target.value == 'weekday') {
                if (channel == 'general') {
                    this.dateGeneral = this.dateDefault
                } else if (channel == 'delivery') {
                    this.dateDelivery = this.dateDefault
                }
                this.load()
            }
        },
        changeDate(e, channel) {
            if (channel == 'general') {
                this.dateGeneral = e.target.value
                if (this.dateDelivery == this.dateDefault) {
                    this.currentDelivery = 'custom'
                    this.dateDelivery = this.dateGeneral
                }
            } else if (channel == 'delivery') {
                this.dateDelivery = e.target.value
            }

            this.load()
        },
        confirmForecast() {
            this.$popup.projection();
            const popupTimer = setTimeout(() => {
                this.$popup.close()
                this.$popup.projectionReturn(); 
            }, 40000)
            let self = this
            var data = {
                ref: this.date,
                params: {
                    general: this.dateGeneral,
                    delivery: this.dateDelivery
                }
            }
            this.$store.dispatch('production/confirm', data).then(() => {
                console.warn('dispatch coinfirm')
                self.$store.dispatch('production/loadForecast', self.date).then(() => {
                    clearTimeout(popupTimer)
                    self.$popup.close()
                })
            })
        }
    },
    watch: {
        date(newValue, oldValue) {
            if (newValue != oldValue) {
                this.dateDefault = moment(newValue).locale('en').format('dddd').toLowerCase()
                this.dateGeneral = moment(newValue).locale('en').format('dddd').toLowerCase()
                this.dateDelivery = moment(newValue).locale('en').format('dddd').toLowerCase()
                this.weekday = moment(newValue).locale('en').format('dddd').toLowerCase()
                this.load()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
#production-init {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;

    .title {
        font-family: $text-bold;
        @include font-size(xl);
        margin-bottom: 64px;
        text-align: center;
    }
    .channels {
        display: flex;
        flex-direction: row;
        width: 100%;
        .item {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 16px 8px;
            align-items: center;

            .name {
                font-family: $text-bold;
                @include font-size(m);
                margin-bottom: 16px;
                text-align: center;
                text-transform: uppercase;
            }
            .selector {
                width: 100%;
                display: flex;
                flex-direction: column;
                max-width: 250px;
                height: 100px;

                .input {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 12px;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                    color: $color-black;
                }
            }
            .summary {
                margin-top: 16px;

                span {
                    font-family: $text-bold;
                }
            }
            .chart {
                width: 100%;
                height: 350px;
                background-color: #fff;
                border-radius: 3px;
                float: none;
                margin-top: 16px;
                overflow: hidden;
            }
        }
    }

    .btnExit {
        .btn {
            padding: 16px;
            &.save-change {
            }
        }
    }
}
</style>
